import { getParamStringFromObject } from "~/utils/helpers";
import { InvoiceListResponse } from "./types";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

export type InvoiceFilters = {
  /**
   * Formatted as YYYY-MM-DD
   */
  from?: string;
  days?: number;
  lines?: boolean;
};

export const _listInvoices = async (
  filters?: Partial<InvoiceFilters>
): Promise<InvoiceListResponse> => {
  const today = new Date().toISOString().split("T")[0];
  const defaultFilters: InvoiceFilters = {
    from: today,
    days: 365,
  };
  const merged: InvoiceFilters = { ...defaultFilters, ...filters };

  const params = getParamStringFromObject(merged);

  // await simulateApiError({path: `/invoices${params}`})

  const data = await fetchAPI2<InvoiceListResponse>(`/invoices${params}`);

  return data;
};
