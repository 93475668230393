import { query, createAsync } from "@solidjs/router";
import { _listInvoices, type InvoiceFilters } from "../roma-api/invoices";

export const getInvoices = query(
  async (filters?: InvoiceFilters) => _listInvoices(filters),
  "invoices"
);

export const useInvoices = (
  filters: () => InvoiceFilters = () => ({}),
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getInvoices(filters()), options);
